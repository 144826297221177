/* You can add global styles to this file, and also import other style files */

html, body {
  background-color: #fafafa;
  margin: 0;
  padding: 0;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.clickable-list-item, .clickable{
  cursor: pointer;
}

.mat-tab-labels{
  border-bottom: solid #50ba75;
}

.mat-tab-label{
  font-size: 1.2em !important;
  opacity: 1 !important;
  font-weight: normal;
}

.mat-button-toggle {
  flex: auto;
  width: 100%;
}

.mat-tab-label-active{
  background-color: #50ba75;
  color: white !important;
}

.mat-button-toggle-label-content {
  line-height: 20px !important;
}

.mat-button-toggle-checked {
  color:white!important;
  background-color:  #088f36 !important;
}

.bg-accent {
  background-color: #000000 !important;
  color:white !important;
}

button {
  transition: none !important;
  box-shadow: none !important;
}

#mat-menu-panel-0{
  max-width: 600px;
}

.flex {
  display:flex;
  width: 100%;
}

.flex .spacer {
  flex:auto;
}

.flex button {
  margin-left:10px;
}
